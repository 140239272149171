"use client";

import { ArrowRight, Menu } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
const MobileNav = ({ isAuth }: { isAuth: boolean }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleOpen = () => setOpen((prev) => !prev);

  const pathname = usePathname();

  useEffect(() => {
    if (isOpen) toggleOpen();
  }, [pathname]);

  const closeOnCurrent = (href: string) => {
    if (pathname === href) {
      toggleOpen();
    }
  };

  const navigation = [
    { name: "Waitlist", href: "/waitlist" },
    { name: "Pricing", href: "#pricing" },
    { name: "Status", href: "https://examvault.openstatus.dev/" },
    { name: "Contact", href: `/contact` },
  ];

  return (
    <div className="lg:hidden">
      <Menu
        onClick={toggleOpen}
        className="relative z-50 h-5 w-5 text-zinc-700"
      />

      {isOpen ? (
        <div className="fixed animate-in slide-in-from-top-5 fade-in-20 inset-0 z-0 w-full">
          <ul className="absolute bg-black shadow-xl grid w-full gap-3 px-10 pt-20 pb-8">
            <div className="flex md:flex-1 lg:flex-1">
              <Link href="/" className="-m-1.5 p-1.5 text-white">
                <span className="sr-only">examvault</span>
                <h2 className="font-sans lg:text-2xl pb-4 font-bold tracking-tight text-white sm:text-2xl">
                  exam
                  <span className="text-[#6B4CFF]">vault</span>
                </h2>
              </Link>
            </div>
            {!isAuth ? (
              <>
                <li>
                  <Link
                    className="flex items-center w-full font-semibold"
                    href="/waitlist"
                  >
                    Waitlist
                  </Link>
                </li>
                <li>
                  <Link
                    className="flex items-center w-full font-semibold"
                    href="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    className="flex items-center w-full font-semibold"
                    href="https://examvault.openstatus.dev/"
                  >
                    Status
                  </Link>
                </li>
                <li>
                  <Link
                    className="flex items-center w-full font-semibold"
                    href="/contact"
                  >
                    Contact
                  </Link>
                </li>
                <li className="my-3 h-px w-full bg-gray-300" />
                <li>
                  <Link
                    onClick={() => closeOnCurrent("/sign-in")}
                    className="flex items-center w-full font-semibold"
                    href="/api/auth/login"
                  >
                    Sign in
                  </Link>
                </li>
                <li className="my-3 h-px w-full bg-gray-300" />
             
              </>
            ) : (
              <>
                <li>
                  <Link
                    onClick={() => closeOnCurrent("/dashboard")}
                    className="flex items-center w-full font-semibold"
                    href="/dashboard"
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="my-3 h-px w-full bg-gray-300" />
                <li>
                  <Link
                    className="flex items-center w-full font-semibold"
                    href="/api/auth/logout"
                  >
                    Sign out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default MobileNav;
