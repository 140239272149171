import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/clients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/features.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/highlighter.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/particles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/pricing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ui/mobilenavbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/feature-image-03.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/glow-bottom.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/Illustration.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/Illustration2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/referral.png");
