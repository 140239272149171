"use client";

import { useState } from "react";

export default function Pricing() {
  const [annual, setAnnual] = useState<boolean>(true);

  return (
    <section id="pricing" className="relative">
      {/* Radial gradient */}
      <div
        className="absolute inset-0 overflow-hidden pointer-events-none -z-10"
        aria-hidden="true"
      >
        <div className="absolute flex items-center justify-center top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-1/3 aspect-square">
          <div className="absolute inset-0 translate-z-0 bg-purple-500 rounded-full blur-[120px] opacity-50" />
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Content */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-3">
                Pricing plans
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-indigo-600">
                Pricing for everyone
              </span>
            </h2>
            <p className="text-lg text-slate-400">
              Explore our clear and adaptable pricing plans, tailored to suit
              your requirements. Choose from individual to enterprise options,
              all packed with premium features for an exceptional experience.
            </p>
          </div>
          {/* Pricing tabs */}
          <div className="relative">
            {/* Blurred shape */}
            <div
              className="max-md:hidden absolute bottom-0 -mb-20 left-2/3 -translate-x-1/2 blur-2xl opacity-70 pointer-events-none"
              aria-hidden="true"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                <defs>
                  <linearGradient
                    id="bs5-a"
                    x1="19.609%"
                    x2="50%"
                    y1="14.544%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#A855F7" />
                    <stop offset="100%" stopColor="#6366F1" stopOpacity="0" />
                  </linearGradient>
                </defs>
                <path
                  fill="url(#bs5-a)"
                  fillRule="evenodd"
                  d="m661 736 461 369-284 58z"
                  transform="matrix(1 0 0 -1 -661 1163)"
                />
              </svg>
            </div>
            {/* Content */}
            <div className="grid md:grid-cols-4 xl:-mx-6 text-sm [&>div:nth-of-type(-n+4)]:py-6 [&>div:nth-last-of-type(-n+4)]:pb-6 max-md:[&>div:nth-last-of-type(-n+4)]:mb-8 max-md:[&>div:nth-of-type(-n+4):nth-of-type(n+1)]:rounded-t-3xl max-md:[&>div:nth-last-of-type(-n+4)]:rounded-b-3xl md:[&>div:nth-of-type(2)]:rounded-tl-3xl md:[&>div:nth-of-type(4)]:rounded-tr-3xl md:[&>div:nth-last-of-type(3)]:rounded-bl-3xl md:[&>div:nth-last-of-type(1)]:rounded-br-3xl [&>div]:bg-slate-700/20 [&>div:nth-of-type(4n+1)]:bg-transparent max-md:[&>div:nth-of-type(4n+5)]:hidden max-md:[&>div:nth-of-type(4n+2)]:order-1 max-md:[&>div:nth-of-type(4n+3)]:order-2 max-md:[&>div:nth-of-type(4n+4)]:order-3 max-md:md:[&>div:nth-of-type(n)]:mb-0 [&>div:nth-of-type(4n+3)]:relative before:[&>div:nth-of-type(4n+3)]:absolute before:[&>div:nth-of-type(4n+3)]:-inset-px before:[&>div:nth-of-type(4n+3)]:rounded-[inherit] before:[&>div:nth-of-type(4n+3)]:border-x-2 before:[&>div:nth-of-type(3)]:border-t-2 before:[&>div:nth-last-of-type(2)]:border-b-2 before:[&>div:nth-of-type(4n+3)]:border-purple-500 before:[&>div:nth-of-type(4n+3)]:-z-10 before:[&>div:nth-of-type(4n+3)]:pointer-events-none">
              {/* Pricing toggle */}
              <div className="px-6 flex flex-col justify-end">
                <div className="pb-5 md:border-b border-slate-800">
                  {/* Toggle switch */}
                  <div className="max-md:text-center">
                    <div className="inline-flex items-center whitespace-nowrap">
                      <div className="text-sm text-slate-500 font-medium mr-2 md:max-lg:hidden">
                        Monthly
                      </div>
                      <div className="relative">
                        <input
                          type="checkbox"
                          id="toggle"
                          className="peer sr-only"
                          checked={annual}
                          onChange={() => setAnnual(!annual)}
                        />
                        <label
                          htmlFor="toggle"
                          className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-slate-400 px-0.5 outline-slate-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow-sm before:transition-transform before:duration-150 peer-checked:bg-purple-500 peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-purple-500"
                        >
                          <span className="sr-only">Pay Yearly</span>
                        </label>
                      </div>
                      <div className="text-sm text-slate-500 font-medium ml-2">
                        Yearly <span className="text-teal-500">(-20%)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Pro price */}
              <div className="px-6 flex flex-col justify-end">
                <div className="grow pb-4 mb-4 border-b border-slate-800">
                  <div className="text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-0.5">
                    Basic
                  </div>
                  <div className="mb-1">
                    <span className="text-lg font-medium text-slate-500">
                      د.إ
                    </span>
                    <span className="text-3xl font-bold text-slate-50">
                      {annual ? "40" : "50"}
                    </span>
                    <span className="text-sm text-slate-600 font-medium">
                      /mo
                    </span>
                  </div>
                  <div className="text-slate-500">
                    Everything at your fingertips.
                  </div>
                </div>
                <div className="pb-4 border-b border-slate-800">
                  <a
                    className="btn-sm text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group"
                    href="/waitlist"
                  >
                    Get Started{" "}
                    <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                      -&gt;
                    </span>
                  </a>
                </div>
              </div>
              {/* Team price */}
              <div className="px-6 flex flex-col justify-end">
                <div className="grow pb-4 mb-4 border-b border-slate-800">
                  <div className="text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-0.5">
                    Subject Specialist
                  </div>
                  <div className="mb-1">
                    <span className="text-lg font-medium text-slate-500">
                      د.إ
                    </span>
                    <span className="text-3xl font-bold text-slate-50">
                      {annual ? "80" : "100"}
                    </span>
                    <span className="text-sm text-slate-600 font-medium">
                      /mo
                    </span>
                  </div>
                  <div className="text-slate-500">
                    Everything at your fingertips.
                  </div>
                </div>
                <div className="pb-4 border-b border-slate-800">
                  <a
                    className="btn-sm text-white bg-purple-500 hover:bg-purple-600 w-full transition duration-150 ease-in-out group"
                    href="/waitlist"
                  >
                    Get Started{" "}
                    <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                      -&gt;
                    </span>
                  </a>
                </div>
              </div>
              {/* Enterprise price */}
              <div className="px-6 flex flex-col justify-end">
                <div className="grow pb-4 mb-4 border-b border-slate-800">
                  <div className="text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-0.5">
                    Grade 9 Pack
                  </div>
                  <div className="mb-1">
                    <span className="text-lg font-medium text-slate-500">
                      د.إ
                    </span>
                    <span className="text-3xl font-bold text-slate-50">
                      {annual ? "160" : "200"}
                    </span>
                    <span className="text-sm text-slate-600 font-medium">
                      /mo
                    </span>
                  </div>
                  <div className="text-slate-500">
                    Everything at your fingertips.
                  </div>
                </div>
                <div className="pb-4 border-b border-slate-800">
                  <a
                    className="btn-sm text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group"
                    href="/waitlist"
                  >
                    Get Started{" "}
                    <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                      -&gt;
                    </span>
                  </a>
                </div>
              </div>
              {/* # Usage */}
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4">Perks</div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 md:hidden">
                  Perks
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 md:hidden">
                  Perks
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 md:hidden">
                  Perks
                </div>
              </div>
              {/* Subjects */}
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Subjects
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    1 <span className="md:hidden">Subjects</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    4 <span className="md:hidden">Subjects</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    Unlimited <span className="md:hidden">Subjects</span>
                  </span>
                </div>
              </div>
              {/* AI Usage */}
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  AI Usage (Soon)
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    None <span className="md:hidden">AI Usage (Soon)</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    Rate Limited <span className="md:hidden">AI Usage (Soon)</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    Unlimited <span className="md:hidden">AI Usage (Soon)</span>
                  </span>
                </div>
              </div>
              {/* Flashcards (Soon) */}
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Flashcards (Soon)
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    None <span className="md:hidden">Flashcards (Soon)</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    100 / month{" "}
                    <span className="md:hidden">Flashcards (Soon)</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    Unlimited{" "}
                    <span className="md:hidden">Flashcards (Soon)</span>
                  </span>
                </div>
              </div>
              {/* Recommended Videos (Soon) */}
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Recommended Videos (Soon)
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    None{" "}
                    <span className="md:hidden">Recommended Videos (Soon)</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    Unlimited{" "}
                    <span className="md:hidden">Recommended Videos (Soon)</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    Unlimited{" "}
                    <span className="md:hidden">Recommended Videos (Soon)</span>
                  </span>
                </div>
              </div>
              {/* # Features */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4">
                  Workshop
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 md:hidden">
                  Workshop
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 md:hidden">
                  Workshop
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 md:hidden">
                  Workshop
                </div>
              </div> */}
              {/* Group Study Sessions */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800 hidden">
                  Group Study Sessions
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  Seperate Subscription
                  <span>
                    <span className="md:hidden">Group Study Sessions</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  Seperate Subscription
                  <span>
                    <span className="md:hidden">Group Study Sessions</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  Seperate Subscriptions
                  <span>
                    <span className="md:hidden">Group Study Sessions</span>
                  </span>
                </div>
              </div> */}
              {/* Advanced Deployment Options */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Advanced Deployment Options
                </div>
              </div> */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">
                      Advanced Deployment Options
                    </span>
                  </span>
                </div>
              </div> */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">
                      Advanced Deployment Options
                    </span>
                  </span>
                </div>
              </div> */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">
                      Advanced Deployment Options
                    </span>
                  </span>
                </div>
              </div> */}
              {/* Extra Add-ons */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Extra Add-ons
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">Extra Add-ons</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">Extra Add-ons</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">Extra Add-ons</span>
                  </span>
                </div>
              </div> */}
              {/* Admin Roles */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Admin Roles
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center border-b border-slate-800 py-2 text-slate-400 max-md:hidden">
                  <span>
                    <span className="md:hidden">Admin Roles</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center border-b border-slate-800 py-2 text-slate-400 max-md:hidden">
                  <span>
                    <span className="md:hidden">Admin Roles</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">Admin Roles</span>
                  </span>
                </div>
              </div> */}
              {/* Deploy and Monitor */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Deploy and Monitor
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center border-b border-slate-800 py-2 text-slate-400 max-md:hidden">
                  <span>
                    <span className="md:hidden">Deploy and Monitor</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center border-b border-slate-800 py-2 text-slate-400 max-md:hidden">
                  <span>
                    <span className="md:hidden">Deploy and Monitor</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">Deploy and Monitor</span>
                  </span>
                </div>
              </div> */}
              {/* Enterprise Add-ons */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Enterprise Add-ons
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center border-b border-slate-800 py-2 text-slate-400 max-md:hidden">
                  <span>
                    <span className="md:hidden">Enterprise Add-ons</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center border-b border-slate-800 py-2 text-slate-400 max-md:hidden">
                  <span>
                    <span className="md:hidden">Enterprise Add-ons</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  <span>
                    <span className="md:hidden">Enterprise Add-ons</span>
                  </span>
                </div>
              </div> */}
              {/* # Support */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4">
                  Support
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 hidden">
                  Support
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 md:hidden">
                  Support
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 md:hidden">
                  Support
                </div>
              </div> */}
              {/* Premium Support */}
              {/* <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-800">
                  Premium Support
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center border-b border-slate-800 py-2 text-slate-400 max-md:hidden">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  Unlimited
                  <span>
                    <span className="md:hidden">Premium Support</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  Unlimited + Priority
                  <span>
                    <span className="md:hidden">Premium Support</span>
                  </span>
                </div>
              </div>
              <div className="px-6 flex flex-col justify-end">
                <div className="flex items-center h-full border-b border-slate-800 py-2 text-slate-400">
                  <svg
                    className="shrink-0 fill-purple-500 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="9"
                  >
                    <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
                  </svg>
                  Unlimited + Priority
                  <span>
                    <span className="md:hidden">Premium Support</span>
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
